import axios from "axios";

export default function requestApi(path, method, data) {
    const url = 'https://api-gain.web-treeosk.online' + path;

    if (method === 'post') {
        return axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    } else if (method === 'get') {
        return axios.get(url, { params: data });
    } else {
        console.log('error promise');
        return Promise.reject(new Error('Méthode de requête invalide'));
    }
}

export const register = async (formData) => {
    const res = await requestApi('/play', 'post', {
        input: {
            game_id: '04daf650-df30-4a68-8f02-844ea5ff4936',
            lang: 'fr',
            player_data: formData,
        }
    });

    return res.data.success;
}