import { useState } from 'react';
import './App.css';
import { register } from './utils/request_apî';

function App() {

const [started, setStarted] = useState(false);
  
  return (
    <div className="App">
      <div style={{textAlign: 'center'}}>
        <img width={'50%'} style={{margin: 'auto'}} src='./logo.svg'/>
      </div>
      {
        !started && <Code setStarted={setStarted}/>
      }
      {
        started && <Form/>
      }
    </div>
  );
}

export default App;


function Code({setStarted}){

  const [code, setCode] = useState('');

  function sub() {
    if(code == 1234){
      setStarted(true);
    }
  }

  return(
    <div style={{maxWidth: '300px', margin: 'auto', marginTop: '60px', padding: "1em", background: 'rgba(255,255,255, 0.8)', borderRadius: '2em', border: '3px solid #153D81'}}>
      <p className='text-center' style={{fontWeight: 'bold'}}>
        Veuillez introduire le code donné par la vendeuse suite à l'achat afin de participer au concours.
      </p>

      <label style={{textAlign: 'start', marginLeft: '0'}} htmlFor='code'>
        Code
      </label>
      <input value={code} onChange={(e)=>{setCode(e.target.value)}} id='code' placeholder='Code' className='form-control'/>

      <div className='text-center mt-5'>
        <button onClick={sub} id='bg-blue' style={{margin: 'auto', borderRadius: '10px', color: 'white'}}>
          Suivant
        </button>
      </div>
    </div>
  );
}

function Form(){

  const [formData, setFormData] = useState({
    gender: null,
    magasin: null,
    email: '',
    last_name: '',
    first_name: '',
    tel: '',
  });

  const [msg, setMsg] = useState('');

  async function sub(e){
    e.preventDefault();

    const res = await register(formData);

    if(res){
      setFormData({
        gender: null,
        magasin: null,
        email: '',
        last_name: '',
        first_name: '',
        tel: '',
      });
      setMsg('Merci pour votre participation');
    }
  }

  return(
    <div style={{maxWidth: '300px', margin: 'auto', marginTop: '60px', padding: "1em", background: 'rgba(255,255,255, 0.8)', borderRadius: '2em', border: '3px solid #153D81'}}>
      <p className='text-center' style={{fontWeight: 'bold'}}>
      Pour participer,
      renseignez vos coordonnées:
      </p>
      <div className='container'>
        <form onSubmit={sub}>
          <div className='row'>
            <div className='col col-6 mt-3'>
              <input required value={formData.last_name} onChange={(e)=>{setFormData({...formData, last_name: e.target.value})}} placeholder='Nom' className='form-control'/>
            </div>
            <div className='col col-6 mt-3'>
              <input required onChange={(e)=>{setFormData({...formData, first_name: e.target.value})}} value={formData.first_name} placeholder='Prénom' className='form-control'/>
            </div>
            <div className='col col-12 mt-3'>
              <select required onChange={(e)=>{setFormData({...formData, gender: e.target.value})}} value={formData.gender} className='form-control text-center'>
                <option value={null}>
                  --Genre--
                </option>
                <option value={'man'}>
                  Homme
                </option>
                <option value={'woman'}>
                  Femme
                </option>
                <option value={'other'}>
                  Autre
                </option>
              </select>
            </div>
            <div className='col col-12 mt-3'>
              <input type='email' required value={formData.email} onChange={(e)=>{setFormData({...formData, email: e.target.value})}} placeholder='Adresse email' className='form-control'/>
            </div>
            <div className='col col-12 mt-3'>
              <input required value={formData.tel} onChange={(e)=>{setFormData({...formData, tel: e.target.value})}} placeholder='Numéro de téléphone' className='form-control'/>
            </div>
            <div className='col col-12 mt-3'>
              <select required onChange={(e)=>{setFormData({...formData, magasin: e.target.value})}} value={formData.magasin} className='form-control text-center'>
                <option value={null}>
                  --Magasin--
                </option>
                <option value={'Meir'}>
                  Meir
                </option>
                <option value={'Genk'}>
                  Genk
                </option>
              </select>
            </div>
          </div>
          <div className='text-center'>
            <button id='bg-blue' style={{margin: 'auto', borderRadius: '10px', color: 'white', marginTop: '20px'}}>
              Confirmer
            </button>
          </div>
        </form>

        <span style={{color: 'green'}}>
          {msg}
        </span>
      </div>
    </div>
  )
}